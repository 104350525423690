.P-product {
  .P-product-header {
    background: #D7D8E1;

    * {
      color: #23232f;
    }

    h3 {
      width: 100% !important;
    }

    .P-product-border {
      border: 2px solid #B4B6C7;
    }
  }

  .G-solution-tab {
    padding: 24px !important;
  }
  .P-product-border {
    border: 2px solid #B4B6C7;

    * {
      color: #23232f;
    }
  }
}
