@import "src/assets/style";

.P-newsCard {
  //max-width: 250px;
  width: 100%;
  height: 100%;
  min-width: 212px;
  margin: 0 auto;
  min-height: 290px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  padding-bottom: 15px;
  box-sizing: border-box;
  //overflow: hidden;

  &:hover {
    img {
      transform: scale(1.08);
    }
  }

  h5 {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #434353;
    margin-bottom: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    margin-bottom: 20px !important;
    color: #23232f !important;
    text-align: left !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  img {
    z-index: 0;
    object-fit: contain;
    height: auto;
    width: 100%;
    display: block;
    -webkit-transition: 0.3s ease-out;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    @include screen-max-width-991 {
      -webkit-transition: none !important;
      transition: none !important;
      -moz-transition: none !important;
      -ms-transition: none !important;
      -o-transition: none !important;
    }
    //max-height: 135px;
  }

  span {
    cursor: pointer;
    font-size: 11px;
    line-height: 16px;
    font-size: 14px;
    text-decoration-line: underline;
    color: $blue;
  }

  .P-content {
    position: relative;
    height: 170px;
    width: 100%;
    margin-top: -10px;
    border-radius: 4px;
    padding: 15px;
    background: #ffffff;
    z-index: 10;
  }
}
