@charset "UTF-8";

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  mask-size: contain;
  cursor: pointer;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  font-size: 16px;
  background-color: #000;
}

/*.icon.vk {*/
/*    mask-image: url(vk.svg);*/
/*    -webkit-mask-image: url(vk.svg);*/
/*}*/

.icon.drop-down {
  mask-image: url(./icons-svg/drop-downsvg.svg);
  -webkit-mask-image: url(./icons-svg/drop-downsvg.svg);
}

.icon.menu{
  mask-image: url(./icons-svg/menu.svg);
  -webkit-mask-image: url(./icons-svg/menu.svg);
}