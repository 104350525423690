@import "flex";
@import "normalize";
@import "global";
@import "variables";
@import "mixin";
@import "solutions";
@import "../icons/icons";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://use.typekit.net/hqo0qio.css");
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

html[lang="ru"] * {
  font-family: montserrat, sans-serif;
}

* {
  font-family: "Poppins", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.P-icons {
  img {
    cursor: pointer;
  }
}

.animation {
  opacity: 0;
  @include screen-max-width-991 {
    transition: none !important;
  }
}

.slideInDown.animation {
  transition: all 1s cubic-bezier(1, 0, 1, 1);
  opacity: 1;
  animation: slideInDown 2s forwards;
  width: 100%;
}

.slideToRight.animation {
  transition: all 1s cubic-bezier(1, 0, 1, 1);
  opacity: 1;
  animation: slideToRight 2s forwards;
  width: 100%;
}

.slideToRight .loop {
  animation: slideFromLeft 24s ease-in-out infinite;
  animation-delay: 2s;
}

.loop {
  width: fit-content !important;
}

.slideToLeft .loop {
  animation: slideFromRight 24s ease-in-out infinite;
  animation-delay: 2s;
}

.slideToLeft.animation {
  transition: all 1s cubic-bezier(1, 0, 1, 1);
  opacity: 1;
  animation: slideToLeft 2s forwards;
  width: 100%;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  40% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideToRight {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideToLeft {
  0% {
    opacity: 0;
    transform: translateX(80px);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

.calculation {
  width: inherit !important;
  height: inherit !important;
  font: inherit !important;
  color: inherit !important;
  display: inline-block !important;
  font-size: inherit !important;
}
