@import "src/assets/style/index.scss";

.P-social-icons {
  img {
    cursor: pointer;
  }
}

.P-web {
  @include screen-max-width-671 {
    display: none;
  }
}

.P-mobile {
  @include screen-min-width-671 {
    display: none;
  }
}

.P-footer {
  margin: auto 0 0;
  width: 100%;
  @include screen-max-width-991 {
  }

  * {
    color: #ffffff;
  }

  .P-contacts-area-container {
    background: $blue;
    height: 250px;
    width: 100%;

  }

  .P-contacts-area {
    width: 100%;
    text-align: center;
    height: 250px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    h3 {
      font-size: $font-size-24;
      font-weight: 600;
      line-height: 36px;
      /* identical to box height */
      color: #ffffff;
    }

    p {
      font-size: $font-size-16;
      width: 60%;
      min-width: 240px;
      color: #ffffff;
    }

    .P-btns {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      button {
        font-size: $font-size-14;
        color: #141418;
        background: #ffffff;
        min-width: 100px;
        padding: 0 10px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
  }

  .P-footer-nav.P-mobile {
    min-height: 360px;
    height: auto;
    padding-bottom: 30px;

    .G-container {
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .P-footer-navigation {
        width: 100%;
        gap: 10px;
        justify-content: space-between;
      }

      .P-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 35%;
      }

      .P-social-icons {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        font-weight: 600;

        .P-icons {
          display: flex;
          gap: 15px;
        }
      }

      .logo {
        height: 27px;
      }
    }
  }

  .P-footer-nav {
    width: 100%;
    min-height: 360px;
    background: #141418;
    padding-top: 56px;

    a {
      color: #a9a9b1;
      line-height: 1.5;
      padding: 5px 0;
    
    }

    .G-container {
      display: flex;
    }

    .P-footer-logo {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 47px;

      .P-social-icons {
        display: flex;
        flex-direction: column;
        gap: 11px;

        .P-icons {
          display: flex;
          gap: 21px;
        }
      }

      .logo {
        height: 27px;
      }
    }

    * {
      font-size: $font-size-14;
    }

    .P-footer-navigation {
      width: 40%;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .P-sections {
        line-height: 30px;
        max-width: 50%;
        @include screen-max-width-671 {
          max-width: 100%;
        }

        a {
          display: block;
        }
      }
    }
  }
}
