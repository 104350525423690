@import "src/assets/style/index.scss";

body:has( .P-mobile-menu.active ) {
  overflow: hidden;
  height: 100vh;
}

.P-header {
  position: relative;
  padding: 40px 0 40px;
  background: transparent;
  z-index: 99;
  -webkit-transition: 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  @include screen-max-width-991 {
    -webkit-transition: none !important;
    transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
  }


  .P-text {
    display: flex;
    align-items: center;

    img {
      margin-left: 5px;
    }
  }

  .dot {
    display: block;
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: $blue;
    bottom: -10px;
    transform: translateX(-50%);
    -webkit-transition: 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    @include screen-max-width-991 {
      -webkit-transition: none !important;
      transition: none !important;
      -moz-transition: none !important;
      -ms-transition: none !important;
      -o-transition: none !important;
    }
  }


  @include screen-max-width-991 {
    padding: 20px 0;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #FFFFFF;

    .P-menu-icon.icon.menu {
      width: 40px;
      height: 40px;

    }
  }
  width: 100%;
  z-index: 9999;

  * {
    color: #23232f;
    cursor: pointer;
    font-size: 14px;
  }

  &.white {
    position: relative;

    .P-menu-icon.icon.menu {
      background-color: #FFFFFF;
    }

    * {
      color: #ffffff;
    }

    .active.P-options,
    ul.active {
      background: #ffffff !important;

      * {
        color: #23232f;
      }
    }

    @include screen-max-width-991 {
      background-color: transparent;

      .active.P-options,
      ul.active {
        background: transparent !important;

        * {
          color: #FFFFFF;
        }
      }
    }

    .dot {
      background: #ffffff;
    }

    .P-logo {

      img {
        opacity: 0;
      }

      a {
        width: fit-content;
        mask-image: url(../../assets/img/logo.svg);
        -webkit-mask-image: url(../../assets/img/logo.svg);
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        background-color: #FFFFFF;
      }

      @include screen-max-width-991 {

        a {
          width: fit-content;
          mask-image: url(../../assets/img/logo.svg);
          -webkit-mask-image: url(../../assets/img/logo.svg);
          mask-size: contain;
          -webkit-mask-size: contain;
          mask-repeat: no-repeat;
          -webkit-mask-repeat: no-repeat;
          mask-position: center;
          -webkit-mask-position: center;
          background-color: transparent;
          background-color: #FFFFFF;

        }
      }
    }


  }

  .P-menu {
    gap: 100px;
    z-index: 9999;

    .P-navigation {
      gap: 48px;
      position: relative;


      .P-dropdown {
        position: relative;

        .P-options {
          background: #ffffff;
          margin-top: 15px;
          width: fit-content;
          max-height: 0;
          position: absolute;
          white-space: nowrap;
          -webkit-transition: 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -ms-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          @include screen-max-width-991 {
            -webkit-transition: none !important;
            transition: none !important;
            -moz-transition: none !important;
            -ms-transition: none !important;
            -o-transition: none !important;
          }
          box-sizing: border-box;
          border: 0px solid #dbdbdb;
          box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          left: 0;

          ul {
            display: block;
            -webkit-transition: 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -ms-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            @include screen-max-width-991 {
              -webkit-transition: none !important;
              transition: none !important;
              -moz-transition: none !important;
              -ms-transition: none !important;
              -o-transition: none !important;
            }
            max-height: 0;
            overflow: hidden;
            width: fit-content;

            li {
              margin: 16px auto;
            }
          }
        }

        .P-options.active {
          max-height: 350px;
          padding: 0 20px;
          border-width: 1px;

          .active {
            color: $blue;
          }

          ul {
            max-height: 350px;
          }

          // &:before {
          //   content: "\25B2";
          //   color: #ffffff;
          //   position: absolute;
          //   z-index: 1;
          //   top: -14px;
          //   right: 0;
          //   left: 60%;
          // }
        }
      }
    }
  }


  .P-mobile-menu.active {
    left: 0;

    .P-background {
      span {
        max-height: 200vh;
        max-width: 200vw;
      }
    }
  }

  .P-mobile-menu.close {
    .P-background {
      span {
        max-height: 50vh;
        max-width: 50vw;
      }
    }
  }


  .P-white-background {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: -1;
  }


  .P-mobile-menu {
    position: fixed;
    background-color: $blue;

    -webkit-transition: 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    @include screen-max-width-991 {
      -webkit-transition: none !important;
      transition: none !important;
      -moz-transition: none !important;
      -ms-transition: none !important;
      -o-transition: none !important;
    }
    z-index: 99;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 100vw;
    height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;

    .P-background {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;

      span {
        -webkit-transition: 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
        -moz-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        @include screen-max-width-991 {
          -webkit-transition: none !important;
          transition: none !important;
          -moz-transition: none !important;
          -ms-transition: none !important;
          -o-transition: none !important;
        }
        position: absolute;
        top: -50%;
        right: -50%;
        background-color: $blue;
        display: block;
        width: 200%;
        height: 200%;
        border-radius: 50vh;
        z-index: 0;
        max-width: 50vw;
        max-height: 50vh;
      }
    }

    * {
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      color: #ffffff;
    }

    .P-selector {
      ul {
        background: $blue !important;

        * {
          font-size: 20px;
        }
      }
    }

    * {
      color: white;
    }

    .P-top {
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 0px;
      // height: 25px;
      padding: 20px 20px 20px 20px;

      align-items: center;
    }

    .P-nav {
      height: calc(100% - 133px);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 68px;

      .P-navigation {
        margin-bottom: 40px;
        z-index: 1;
      }

      a {
        margin-top: 40px;
      }

      li a {
        margin-top: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        color: #ffffff;
      }

      .P-dropdown {
        margin-top: 40px;
        position: relative;

        .P-options {
          width: 100%;
          max-height: 0;
          white-space: nowrap;
          -webkit-transition: 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -ms-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          @include screen-max-width-991 {
            -webkit-transition: none !important;
            transition: none !important;
            -moz-transition: none !important;
            -ms-transition: none !important;
            -o-transition: none !important;
          }
          border-radius: 15px;
          right: -100%;
          //   box-shadow: 0px 0px 150px 13px rgba(0, 0, 0, 0.1);
          ul {
            display: block;
            -webkit-transition: 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -ms-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            @include screen-max-width-991 {
              -webkit-transition: none !important;
              transition: none !important;
              -moz-transition: none !important;
              -ms-transition: none !important;
              -o-transition: none !important;
            }
            max-height: 0;
            overflow: hidden;
            width: fit-content;

            li {
              margin: 3px auto;
            }
          }
        }

        .P-options.active {
          max-height: 350px;
          padding: 15px;

          ul {
            max-height: 350px;
          }
        }
      }
    }
  }

  .P-language-bar {
    width: 100px;

    img {
      margin-left: 5px;
      max-width: 24px;
      max-height: 14px;
      object-fit: cover;
      width: 100%;
      border-radius: 2px;

    }

    .P-arrow {
      position: absolute;
      right: -5px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .P-selector {
      position: relative;

      .P-title {
        position: relative;
        //gap: 10px;
        padding: 0 20px;
      }

      ul {
        width: 100px;
        position: absolute;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
        -webkit-transition: 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        padding: 0 20px;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //gap: 10px;

          img {
            border-radius: 2px;
          }
        }

        @include screen-max-width-991 {
          -webkit-transition: none !important;
          transition: none !important;
          -moz-transition: none !important;
          -ms-transition: none !important;
          -o-transition: none !important;
        }
        max-height: 0;
        overflow: hidden;
        border: 0px solid #dbdbdb;
        text-align: center;

        img {
          object-fit: cover;
          width: 100%;
          margin-left: 5px;
        }
      }

      ul.active {
        border-width: 1px;
        max-height: 250px;
        padding: 10px 20px;
        z-index: 2;

        @include screen-max-width-991 {
          bottom: 100%;
        }

      }
    }
  }
}
