.P-design {
  .P-design-header {
    background: #ec80ec;

    * {
      color: #ffffff;
    }

    .P-design-border {
      border: 2px solid #ffc2ff;
    }
  }

  .G-solution-tab {
    padding: 34px !important;
  }

  .P-design-border {
    border: 2px solid #ffc2ff;

    * {
      color: #ffffff;
    }
  }
}
