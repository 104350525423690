.P-financial {
  .P-financial-header {
    background: #00836d;

    * {
      color: #ffffff;
    }

    .P-financial-border {
      border: 2px solid #2da893;
    }
  }

  .G-solution-tab {
    padding: 20px !important;
  }

  .P-financial-border {
    border: 2px solid #2da893;

    * {
      color: #ffffff;
    }
  }
}
