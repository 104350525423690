@import "src/assets/style/index.scss";

.P-news {
  padding-bottom: 124px;

  .P-newsCard {
    max-width: 100%;
  }

  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #23232f;
    margin-bottom: 51px;
    margin-top: 130px;
    @include screen-max-width-991 {
      margin-top: 32px;
      text-align: left;
    }
  }

  p {
    text-align: center;
    color: #434353;
    margin-bottom: 30px;
    @include screen-max-width-991 {
      text-align: left;
      margin-bottom: 0px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    line-height: 36px;
    color: #23232f;
    margin-top: 90px;
    margin-bottom: 32px;
    @include screen-max-width-991 {
      margin-top: 61px;
    }
  }

  .P-news-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);

    justify-content: center;
    margin: 0 -10px;

    .P-card {
      width: calc(25%);
      text-align: left;
      padding: 10px;
      @include screen-max-width-991 {
        width: 33.3333%;
      }
      @include screen-max-width-767 {
        width: 50%;
      }
      @include screen-max-width-582 {
        width: 100%;
      }
    }
  }
}
