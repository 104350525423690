// colors

$text-color: #8590a6;
$base-color: #564bd9;
$base-color-1: #5f309c;
$black: #000000;
$grey-80: #252526;
$white: #ffffff;
$red: #e24949;
$red-dark: #e42727;
$red-10: #fee3e3;
$blue: #58a5ff;
$blue-violet: #564bd9;
$blue-dark: #1a253b;
$blue-90: #31446c;
$blue-85: #919db3;
$blue-10: #dfe1e7;
$text-color: #8590a6;
$true-blue: #0055cc;
$base-color: #564bd9;
$base-color-1: #5f309c;
$black: #000000;
$grey-80: #252526;
$white: #ffffff;
$red: #e24949;
$red-dark: #e42727;
$red-10: #fee3e3;
$blue: #0148FF;
$blue-violet: #564bd9;
$blue-dark: #1a253b;
$blue-90: #31446c;
$blue-85: #919db3;
$blue-10: #dfe1e7;

$grey-base: #343434;
$grey-80: #252526;
$grey-60: #999999;
$grey-61: #b3b3b3;
$grey-62: #9a9a9a;
$grey-65: #e5e5e5;
$grey-55: #eef0f2;
$grey-50: #f2f2f2;
$grey-45: #e6e6e6;
$grey-40: #ebeced;

$black-03: rgba(0, 0, 0, 0.3);

// Font sizes

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-46: 46px;
$font-size-48: 48px;

//page withs

$max-width-1200: 1200px;
$max-width-991: 991px;
$max-width-900: 900px;
$max-width-767: 767px;
$max-width-671: 671px;
$max-width-607: 607px;

$max-width-582: 582px;
$max-width-576: 576px;
$max-width-440: 440px;

// transitions
$transition: 0.3s;
