@import "./variables";

@mixin screen-max-width-1200 {
  @media (max-width: $max-width-1200) {
    @content;
  }
}
@mixin screen-max-width-991 {
  @media (max-width: $max-width-991) {
    @content;
  }
}
@mixin screen-max-width-900 {
  @media (max-width: $max-width-900) {
    @content;
  }
}
@mixin screen-max-width-767 {
  @media (max-width: $max-width-767) {
    @content;
  }
}
@mixin screen-max-width-671 {
  @media (max-width: $max-width-671) {
    @content;
  }
}
@mixin screen-min-width-671 {
  @media (min-width: $max-width-671) {
    @content;
  }
}
@mixin screen-max-width-607 {
  @media (max-width: $max-width-607) {
    @content;
  }
}
@mixin screen-max-width-440 {
  @media (max-width: $max-width-440) {
    @content;
  }
}
@mixin screen-max-width-582 {
  @media (max-width: $max-width-582) {
    @content;
  }
}
@mixin screen-min-width-991 {
  @media (min-width: $max-width-991) {
    @content;
  }
}
