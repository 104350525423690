@import "src/assets/style/index.scss";

.P-about-us {
  .P-about-us-text {
    margin-top: 90px;
    gap: 190px;
    row-gap: 120px;
    padding-bottom: 60px;

    @include screen-max-width-767 {
      flex-direction: column;
      margin-top: 44px;
      row-gap: 58px;
    }

    .P-about-card {
      width: calc(50% - 95px);

      &:nth-child(2) .animation {
        animation-delay: 0.1s;
      }

      &:nth-child(3) .animation {
        animation-delay: 0.2s;
      }

      &:nth-child(4) .animation {
        animation-delay: 0.3s;
      }

      @include screen-max-width-767 {
        width: 100%;
      }

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 35px;
        color: #23232f;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 17px;

        span {
          margin: 0;
        }

        color: #434353;
      }

      span {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 14px;
        color: #23232f;
      }

      .P-icons {
        gap: 13px;
      }

      .P-numbers {
        gap: 37px;

        @include screen-max-width-440 {
          gap: 25px;
        }

        p {
          font-weight: 600;
          font-size: 24px;
          color: $blue;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          color: $blue;
        }
      }
    }
  }

  .P-photos {
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #23232f;
      margin-bottom: 40px;
    }

    .P-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -9px;
      width: calc(100% + 18px);
      justify-content: center;
      row-gap: 56px;
      padding-bottom: 75px;

      @include screen-max-width-767 {
        // Add specific styles for this breakpoint if needed
      }
    }

    .P-photo-card {
      width: 25%;
      padding: 9px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include screen-max-width-991 {
        width: 33.3333%;
      }

      @include screen-max-width-671 {
        width: 50%;
      }

      @include screen-max-width-582 {
        width: 100%;
      }

      .P-photo {
        display: block;
        width: 100%;
        max-width: 250px;
        height: 268px;
        background: #d9d9d9;
        border-radius: 4px;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: top;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
        margin: 14px auto 4px;
        color: #23232f;
      }

      p {
        width: 80%;
        margin: 0 auto;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #434353;
      }
    }
  }
}
