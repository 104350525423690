@import "src/assets/style";

.G-loader {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .G-loader-box {
    width: 50%;
    max-width: 100px;

    img {
      width: 100%;
    }

    .G-loader-container {
      margin: 20px auto 0;
      display: flex;
      width: 25%;
      min-width: 60px;
      position: relative;
      justify-content: space-between;

      .G-loader-dot {
        display: block;
        background-color: $blue;
        width: 10px;
        height: 10px;
        border-radius: 20px;

        &:nth-child(1) {

          animation: scale 2s infinite ease-in-out;
        }

        &:nth-child(2) {
          left: 50%;
          animation: scale 2s infinite ease-in-out;
          animation-delay: 0.5s;
        }

        &:nth-child(3) {
          right: 50%;
          animation: scale 2s infinite ease-in-out;
          animation-delay: 1s;
        }
      }

    }
  }
}


@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }

}