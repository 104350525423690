@import "src/assets/style/index.scss";

.P-clients {
  .P-clients-top {
    text-align: center;
    h2 {
      max-width: 450px;
      width: 90%;
      margin: 88px auto 44px;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #23232f;
      @include screen-max-width-991 {
        margin: 32px 0 24px;
        text-align: left;
      }
    }
    p {
      max-width: 781px;
      width: 100%;
      text-align: center;
      margin: 0 auto 44px;
      color: #434353;
      @include screen-max-width-991 {
        text-align: left;
      }
    }
  }
  .P-clients-logos {
    text-align: center;
    padding-bottom: 53px;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 46px;
      color: #23232f;
    }
    .P-clients-logos-container {
      gap: 20px;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;

        color: #23232f;
      }
      @include screen-max-width-991 {
        gap: 0;
        width: calc(100% + 20px);
        margin: 0 -10px;
        div {
          width: 33.3333%;
          padding: 10px;
        }
      }
    }
  }
  .P-clients-map {
    width: 100%;
    .P-countries {
      gap: 10px;
      align-items: center;
      margin-right: -1px;
      padding: 16px;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #23232f;
      }
      @include screen-max-width-991 {
        width: 80%;
        margin: 0 auto;
        gap: 0;
        p {
          width: 33%;
          text-align: left;
        }
      }
      @include screen-max-width-607 {
        justify-content: center;

        p {
          width: 35%;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          /* identical to box height */

          text-align: left;

          color: #23232f;
        }
      }

      .P-horizontal-divider {
        height: 20px;
        border-left: 1px solid #000000;
        @include screen-max-width-991 {
          display: none;
        }
      }
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      max-width: 385px;
      width: 90%;
      color: #23232f;
      margin: 0 auto 70px;
      @include screen-max-width-991 {
        margin-bottom: 32px;
      }
    }
    .P-clients-map-container {
      width: 100%;
      background: #f5f5f7;
      overflow: hidden;

      img {
        display: block;
        margin: 11px auto 0;
      }
    }
  }
}
