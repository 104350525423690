
.P-loader-icon {
  height: 100%;
  align-items: center;
  margin: 0 auto;
  display: flex;
  width: 25%;
  min-width: 60px;
  position: relative;
  justify-content: space-between;

  .G-loader-dot {
    display: block;
    background-color: #FFFFFF;
    width: 10px;
    height: 10px;
    border-radius: 20px;

    &:nth-child(1) {

      animation: scale 2s infinite ease-in-out;
    }

    &:nth-child(2) {
      left: 50%;
      animation: scale 2s infinite ease-in-out;
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      right: 50%;
      animation: scale 2s infinite ease-in-out;
      animation-delay: 1s;
    }
  }

}

