.P-website {
  .P-website-header {
    background: #ffcd3a;

    * {
      color: #23232f;

    }

    .P-website-border {
      border: 2px solid #ae8b25;
    }
  }

  .G-solution-tab {
    padding: 34px !important;
  }

  .P-website-border {
    border: 2px solid #ae8b25;

    * {
      color: #23232f;
    }
  }
}
