@import "src/assets/style";

.P-booster {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  margin-top: -120.6px;
  padding: 120px 0 20px;
  z-index: 0;

  @include screen-max-width-991 {
    margin-top: -85.6px;
  }

  .P-blur-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -2;
    background: linear-gradient(60.52deg, #0148FF -8.07%, #853BA3 45.63%, #F63054 100%);
  }

  .P-blur {
    transition: all 5s ease-in-out;
    z-index: -1;
    position: absolute;
    background: #01E1FF;
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    filter: blur(100px);
    opacity: 0.7;
  }

  @include screen-max-width-991 {
    transition: none !important;
  }

  .animation:has(.P-content) .P-content {
    transition: all 0.2s ease-in-out;
  }

  @include screen-max-width-991 {
    transition: none !important;
  }

  animation-delay: 0.2s;

  .P-title {
    font-weight: 900;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    color: #FFFFFF;

    img {
      margin-bottom: -7px;
      margin-left: 15px;
      object-position: center;
    }

    @include screen-max-width-991 {
      font-size: 50px;
      line-height: 60px;

      img {
        margin-bottom: -7px;
        margin-left: 10px;
        width: 56px;
        height: 56px;
      }
    }

    @include screen-max-width-607 {
      font-size: 36px;
      line-height: 40px;
      text-align: left;
      width: 100%;

      img {
        margin-bottom: -6px;
        margin-left: 5px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .P-form {
    margin: 0 auto;
    display: flex;
    max-width: 780px;
    flex-wrap: wrap;

    @include screen-max-width-607 {
      margin: 0;
    }

    .P-inputs-container {
      padding: 23px 40px 0;
      width: 50%;

      @include screen-max-width-607 {
        width: 100%;
        padding: 20px 0 0;
      }
    }

    .P-simulate-btn-container {
      margin-top: 20px;

      .P-simulate-btn {
        display: block;
        position: relative;
        width: 100%;
        height: 44px;
        border-radius: 4px;
        background-color: #F63054;
        color: #ffffff;

        &.disabled {
          background-color: #a32044;

          &::before {
            opacity: 0.7;
          }
        }

        &::before {
          position: absolute;
          z-index: -1;
          border-radius: 4px;
          display: block;
          width: 100%;
          height: 100%;
          bottom: -4px;
          content: " ";
          background-color: #B1243E;
        }
      }
    }
  }

  .P-simulating {
    .P-title {
      text-align: center;
      padding: 2px 0;
      animation: opacityLTR 2s ease-in-out forwards;
      background: linear-gradient(to right, #eee 70%, #fff 75%, transparent 80%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

      @include screen-max-width-607 {
        margin-top: 63px;
      }

      @include screen-max-width-440 {
        background: linear-gradient(to right, #eee 80%, #fff 95%, transparent 100%);
        animation: opacityLTRMobile 2s ease-in-out forwards;
      }
    }

    .P-simulator-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 360px;
      width: 70%;

      @include screen-max-width-607 {
        top: 270px;
      }

      .P-done-text {
        position: absolute;
        bottom: -64px;
        width: 100%;
        opacity: 0;
        font-weight: 600;
        font-size: 32px;
        color: #FFFFFF;
        text-align: center;

        &.done {
          animation: done 0.7s ease-in-out forwards;
          opacity: 1;
        }
      }

      .P-simulator {
        border-radius: 20px;
        width: 100%;
        height: 34px;
        padding: 7px;
        background-color: #FFFFFF;
        margin: 0 auto;

        .P-selector-line {
          display: block;
          border-radius: 20px;
          width: 100%;
          max-width: 20px;
          height: 20px;
          background-color: #389DDA;
          transition: all 1s ease-in-out;

          @include screen-max-width-991 {
            transition: none !important;
          }

          &.half {
            max-width: 30%;
          }

          &.full {
            max-width: 100%;
          }
        }
      }
    }
  }

  .P-thanks {
    position: absolute;
    top: 50%;
    max-width: 100vw;
    text-align: center;
    transform: translateY(-50%);

    @include screen-max-width-607 {
      top: 270px;
    }

    .P-h1 {
      margin: 0 auto;
      width: fit-content;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #FFFFFF;

      img {
        margin-bottom: -3px;
        margin-left: 10px;
      }
    }

    .P-text {
      margin: 0 auto;
      width: fit-content;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
    }
  }
}

@keyframes done {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  99% {
    transform: scale(1);
  }
}

@keyframes opacityLTR {
  0% {
    background-position: -50px;
  }
  60% {
    background-position: 50px;
  }
  100% {
    background-position: 50px;
  }
}

@keyframes opacityLTRMobile {
  0% {
    background-position: -15px;
  }
  60% {
    background-position: 15px;
  }
  100% {
    background-position: 15px;
  }
}
