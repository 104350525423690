.P-fraud {
  .P-fraud-header {
    background: #e95642;
    min-height: 550px !important;
    padding-bottom: 20px;

    * {
      color: #ffffff;
    }
    h4 {
      margin: 0 !important;
    }
    .P-fraud-border {
      border: 2px solid #ae8b25;
    }
  }
  .G-solution-tab {
    padding: 34px !important;
  }
}
